const ChevronDown = ({className}: {className: string;}) =>
  <svg className={className} viewBox="0 0 10 6" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(-162.000000, -9.000000)"
        stroke="#151515"
        strokeWidth="1.33333333"
      >
        <g transform="translate(163.000000, 10.000000)">
          <polyline points="0 0 4 4 8 0" />
        </g>
      </g>
    </g>
  </svg>;
export default ChevronDown;
