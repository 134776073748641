import {ImgHTMLAttributes, useContext} from 'react';

import {PlatformContext} from '../platform/context';
import {env} from '../config';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  imgSet: string[];
  fetchpriority?: string;
}

export default function Image({src, alt, imgSet, ...props}: ImageProps) {
  const {imagesHostname} = useContext(PlatformContext);
  const imageSrc = imagesHostname ? src.replace(env.NEXT_PUBLIC_IMAGES_URL as string, imagesHostname) : src;
  return (
    <img
      alt={alt}
      src={imageSrc}
      srcSet={imgSet.map((url: string) => url.replace('{imgurl}', imageSrc)).join(', ')}
      {...props}
    />
  );
}
