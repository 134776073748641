import {ReactElement} from 'react';

import {
  FeaturedDeal,
  PackageOffer,
  PackageResult,
  PlanOffer,
  PlanResult,
  ProductDetail,
  ProductOffer,
  ProductResult,
} from '../generated/graphql';
import {registerInteraction} from '../utils/tracking';

import ChevronRight from './ChevronRight';
import Euro from './Euro';
import FeaturedDeals from './FeaturedDeals';

type Result = ProductResult | PackageResult | PlanResult;
type Offer = ProductOffer | PackageOffer | PlanOffer;

interface ResultsProps {
  results: Result;
  prices?: ProductDetail[];
  ResultCard: (offer: Offer, index: number) => ReactElement;
  OrderBy?: () => ReactElement;
  featuredDeals: FeaturedDeal[];
  renderBetweenResults?: {position: number; Component: ReactElement;}[];
  first: number;
  showMoreResults: () => void;
  resetFilters: () => void;
  loading: boolean;
  hasNonFeaturedFilters: boolean;
}

const RefurbishedNewSwitch = ({prices, firstOffer, productName}: {prices?: ProductDetail[]; firstOffer?: ProductOffer; productName: string;}) => {
  if (!firstOffer || !prices) {
    return null;
  }
  const isRefurbishedView = firstOffer?.condition !== 0;
  const priceAndUrl = prices?.[0]?.advisors?.[isRefurbishedView ? 'newProduct' : 'refurbishedProduct'];

  if (!priceAndUrl?.url) {
    return null;
  }
  return (
    <div
      onClick={() => registerInteraction(`click|${isRefurbishedView ? 'to-new' : 'to-refurbished'}`, 'refurbished-new-switch', 'iphone-12-pro')}
      data-name="refurbished-new-switch"
      className="sf-w-full sf-border-t sf-border-b sf-py-4 sf-flex sf-justify-between sf-items-center"
    >
      <span className="">{(isRefurbishedView && 'Refurbished') || 'Nieuw'}</span>
      <a href={`/${priceAndUrl?.url}` as string} className="sf-text-light-text sf-text-sm hover:sf-text-primary group">
        {(isRefurbishedView && 'Nieuw') || 'Refurbished'} vanaf <Euro amount={priceAndUrl?.lowestPrice as number} />
        <ChevronRight className="sf-m-2 sf-w-[0.40rem] sf-inline sf-font-bold sf-text-light-text hover:sf-stroke-primary" />
      </a>
    </div>
  );
};

const Results = ({results, prices, showMoreResults, first, resetFilters, loading, featuredDeals, ResultCard, OrderBy, renderBetweenResults, hasNonFeaturedFilters}: ResultsProps) => {
  const hasOffers = Boolean(results.offers?.length);
  const cheapestPrice = (results.offers as PackageOffer[] | PlanOffer[])?.[0]?.planPrice || results.offers?.[0]?.totalPrice || 0;

  return (
    <>
      <div data-name="offer-results" className={`${loading ? 'sf-opacity-30' : ''} sf-flex sf-gap-4 sf-flex-col lg:sf-px-4 lg:sf-pl-12`}>
        <div className="sf-hidden lg:sf-flex sf-my-4 sf-justify-between">
          <div className="sf-text-gray-400 sf-italic sf-inline">
            {results.count} resultaten vanaf <Euro amount={cheapestPrice as number} />
          </div>
          {OrderBy && <OrderBy />}
        </div>
        <FeaturedDeals featuredDeals={featuredDeals} />

        { !hasOffers &&
          <div className="sf-mt-8">
            Er zijn geen resultaten gevonden, {`${hasNonFeaturedFilters ? 'pas de filters aan of' : ''} ga terug naar`}
            <span onClick={() => resetFilters()} className="sf-text-primary sf-cursor-pointer">
            {' '}het overzicht met alle aanbiedingen</span>.
          </div>
        }

        {results.__typename === 'ProductResult' &&
          <RefurbishedNewSwitch
            prices={prices}
            firstOffer={results?.offers?.[0] as ProductOffer}
            productName={results?.offers?.[0]?.productName as string}
          />
        }
        {hasOffers && results.offers?.map((offer, index) => {
          const component = renderBetweenResults?.find(({position}) => position === index);
          if (!offer) {
            return null;
          }
          return <div key={offer.deeplink}>
            {component?.Component}
            {ResultCard(offer, index)}
          </div>;
        })}
        {(results.groupCount || 0) > first &&
          <button
            type="button"
            className="sf-cursor-pointer sf-text-gray-400 sf-h-10 sf-w-full sf-text-sm sf-bg-white sf-border sf-border-gray-300 sf-rounded-md hover:sf-border-gray-400 hover:sf-bg-gray-100"
            onClick={() => showMoreResults()}
          >
            Meer resultaten
          </button>
        }
      </div>
    </>
  );
};

export default Results;
