const ChevronRight = ({className}: {className: string;}) =>
  <svg className={className} viewBox="0 0 6 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g transform="translate(-352.000000, -489.000000)" stroke="currentColor" strokeWidth="1.33333333">
        <g transform="translate(353.000000, 490.500000)">
          <g>
            <polyline id="Path" points="0 8 4 4 0 0" />
          </g>
        </g>
      </g>
    </g>
  </svg>;


export default ChevronRight;
