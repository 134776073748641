const FilterIcon = ({className}: {className: string;}) =>
  <svg className={className} viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g transform="translate(-14.000000, -17.000000)" stroke="#000000" strokeWidth="1.2">
        <g transform="translate(15.000000, 18.000000)">
          <g id="filter">
            <polygon id="Path" points="12 0 0 0 4.8 6.30666667 4.8 10.6666667 7.2 12 7.2 6.30666667" />
          </g>
        </g>
      </g>
    </g>
  </svg>;


export default FilterIcon;
