import {PackageOrderBy} from '../generated/graphql';
import useToggle from '../hooks/useToggle';

import ChevronDown from './ChevronDown';
import FilterIcon from './FilterIcon';

interface OrderBy {
  name: string;
  value: PackageOrderBy;
}

interface OrderByProps {
  selectedOrderBy: PackageOrderBy;
  setOrderBy: (orderBy: PackageOrderBy) => void;
  orderBys: OrderBy[];
}

const OrderBy = ({selectedOrderBy, setOrderBy, orderBys}: OrderByProps) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const currentOrderBy = orderBys.find(
    (orderBy) => orderBy.value === selectedOrderBy,
  );

  return (
    <div className="sf-inline sf-relative">
      <span className="sf-hidden lg:sf-inline">Sorteer op:</span>
      <button
        type="button"
        className="sf-items-center sf-w-full lg:sf-w-auto sf-p-2 lg:sf-p-0 sf-flex lg:sf-inline sf-border sf-border-gray-200 sf-rounded-md lg:sf-border-none lg:sf-font-bold"
        onClick={toggleOpen}
      >
        <FilterIcon className="lg:sf-hidden sf-h-4 sf-w-4" />
        <div className="sf-border-l sf-ml-3 sf-pl-3 lg:sf-border-none lg:m-0 lg:p-0">
          <span className="lg:sf-hidden">Sortering</span>
          <span className="sf-hidden lg:sf-inline">
            {currentOrderBy?.name}
          </span>{' '}
          <ChevronDown className="sf-hidden lg:sf-inline sf-ml-1 sf-h-3 sf-w-3" />
        </div>
      </button>
      <div
        className={`${isOpen ? '' : 'sf-hidden'} sf-z-10 sf-drop-shadow-sm sf-border sf-border-gray-200 sf-absolute sf-w-full sf-py-1 sf-mt-2 sf-rounded-b-md sf-bg-white`}
      >
        {orderBys.map((orderBy) =>
          <div
            key={orderBy.value}
            className={`${currentOrderBy?.value === orderBy.value ? 'sf-bg-primary/25' : ''} sf-px-2 sf-w-full sf-pl-4 sf-py-1 hover:sf-bg-primary hover:sf-bg-opacity-25 sf-text-black sf-cursor-pointer`}
            onClick={() => {
              setOrderBy(orderBy.value);
              toggleOpen();
            }}
          >
            {orderBy.name}
          </div>)}
      </div>
    </div>
  );
};

export default OrderBy;
