import qs from 'query-string';
import Cookies from 'js-cookie';
import {ReactNode} from 'react';

interface QueryParams {
  originalDeeplink: string;
  zone: string;
  shopLogoUrl: string;
  position: number | string;
  subposition?: number;
}

interface OfferLinkProps {
  children: ReactNode;
  className: string;
  href: string;
  queryParams: QueryParams;
  onClick: () => void;
}

const OfferLink = (props: OfferLinkProps) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const queryParams = {
    original_deeplink: props.queryParams.originalDeeplink,
    position: props.queryParams.position,
    shop_logo_url: props.queryParams.shopLogoUrl,
    subposition: props.queryParams.subposition,
    zone: props.queryParams.zone,
  };
  /* eslint-enable @typescript-eslint/naming-convention */
  const modifiedDeeplink = _createDeeplink(props.href, queryParams);

  return (
    <a
      href={modifiedDeeplink}
      onClick={(event) => _linkClick(event, props.href, queryParams, props.onClick)}
      className={props.className}
      rel="noopener nofollow"
    >
      {props.children}
    </a>
  );
};

const _linkClick = (event: React.MouseEvent<HTMLAnchorElement>, href: string, queryParams: object, onClick: () => void) => {
  event.preventDefault();
  onClick();
  /* eslint-disable @typescript-eslint/naming-convention */
  const params = {
    ...queryParams,
    ga_uid: Cookies.get('_ga'),
    referer: window.location.href,
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  window.open(_createDeeplink(href, params));
};

const _createDeeplink = (href: string, queryParams: object) => {
  return [
    `/${href}`,
    qs.stringify(queryParams),
  ].join('?');
};

export default OfferLink;
