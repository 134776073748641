import Link from 'next/link';
import {useContext} from 'react';

import {BaseFilter, FilterOption} from '../advisor-config/utils';
import {PackageOrderBy, Product} from '../generated/graphql';
import {SelectedFilters} from '../types/SelectedFilters';

import Filter from './filters/Filter';
import {FiltersRecord} from './filters/Filters';
import Stars from './Stars';
import Image from './Image';
import {StickyHeaderContext} from './Layout';
import FilterIcon from './FilterIcon';
import PackageOrderBySelect from './PackageOrderBySelect';

interface ProductHeaderProps {
  product?: Product;
  review?: {
    score?: number;
    url?: string;
  };
  filters: FiltersRecord;
  setFilterOption: (filter: BaseFilter, option: FilterOption) => void;
  selectedFilters: SelectedFilters;
  toggleMenu: () => void;
  selectedOrderBy?: PackageOrderBy;
  setOrderBy?: (orderBy: PackageOrderBy) => void;
  hasNonFeaturedFilters: boolean;
  hasFeaturedFilters: boolean;
}

export default function ProductHeader({
  product,
  review,
  filters,
  setFilterOption,
  selectedFilters,
  toggleMenu,
  selectedOrderBy,
  setOrderBy,
  hasNonFeaturedFilters,
  hasFeaturedFilters,
}: ProductHeaderProps) {
  const isSticky = useContext(StickyHeaderContext);

  const headerFilters = Object.entries(filters).filter(
    ([, filter]) => filter.featured,
  );

  return (
    <div
      data-name="product-header"
      className={`${
        isSticky ? 'sf-z-20' : ''
      }  sf-sticky sf-z-20 sf-transition sf-duration-700 sf-top-0 sf-block sf-bg-[#f8f8f8]`}
    >
      {product &&
      <div className="sf-max-w-screen-xl sf-px-4 md:sf-px-8 xl:sf-px-0 sf-w-full sf-mx-auto sf-grid sf-grid-cols-1 lg:sf-grid-cols-[1fr_2.2fr]">
        <div className="sf-col-span-full">
          <div key="product-header">
            <div
              className={`${
                isSticky ? 'sf-py-2 lg:sf-py-0' : 'sf-py-3 lg:sf-py-6'
              } sf-transition-[padding] sf-items-center sf-duration-700 sf-grid sf-grid-cols-[auto_1fr_auto]`}
            >
              {product.imageUrl &&
                <Image
                  src={product.imageUrl}
                  alt={`${product.brand.name} ${product.name}`}
                  imgSet={[
                    '{imgurl}?w=88&h=88&fit=fill&bg=f8f8f8 88w',
                    '{imgurl}?w=176&h=176&fit=fill&bg=f8f8f8 176w',
                    '{imgurl}?w=168&h=168&fit=fill&bg=f8f8f8 168w',
                    '{imgurl}?w=336&h=336&fit=fill&bg=f8f8f8 336w',
                  ]}
                  sizes="(max-width: 1023px) 88px, (min-width: 1024px) 168px, 336px"
                  className={`${
                    isSticky
                      ? 'sf-mr-2 sf-h-[3rem] sf-w-[3rem] lg:sf-h-[4rem] lg:sf-w-[4rem]'
                      : 'sf-mr-4 sf-h-[5.5rem] sf-w-[5.5rem] lg:sf-h-[10.5rem] lg:sf-w-[10.5rem]'
                  } sf-transition-[height] sf-duration-700 sf-object-contain lg:sf-mr-14`}
                  fetchpriority="high"
                />
              }
              <div className="sf-flex sf-flex-col sf-justify-between lg:sf-py-5 sf-h-full">
                {!isSticky && review?.url && review?.score &&
                  <div className="sf-flex sf-space-x-2 sf-items-center">
                    <Link href={review.url} passHref>
                        <Stars
                          score={review.score}
                          customClasses="sf-h-5 sf-w-5"
                        />
                    </Link>
                    <span className="sf-text-xs sf-text-light-text">
                      Reviewscore
                    </span>
                  </div>
                }
                { hasFeaturedFilters &&
                <div className="sf-hidden only:sf-mt-auto lg:sf-flex sf-gap-12">
                  {headerFilters.map(([identifier, filter]) =>
                    <Filter
                      key={identifier}
                      filter={filter}
                      selectedOptions={selectedFilters[identifier] || []}
                      handleOnChange={setFilterOption}
                      showLabels={!isSticky}
                      compact
                    />)}
                </div>
                }
                <div className="sf-flex sf-justify-between sf-flex-col sf-w-full">
                  <div className="sf-font-bold sf-text-sm lg:sf-hidden sf-pb-1">
                    {product.brand.name} {product.name}
                  </div>
                  { hasFeaturedFilters &&
                    <div
                      className={`${isSticky && 'sf-mr-auto'} sf-mt-auto sf-grid sf-min-w-[7rem] sf-max-w-[14rem] sf-grid-cols-[1fr_auto] lg:sf-hidden sf-border sf-border-gray-300 sf-bg-white sf-px-2 sf-py-1 sf-items-center sf-divide-x sf-cursor-pointer`}
                      onClick={toggleMenu}
                    >
                      <div className="sf-grid sf-grid-cols-2 sf-rows-2 sf-gap-2 group">
                        {headerFilters.map(([identifier, filter], index) => {
                          const selectedOption = selectedFilters[identifier]?.[0];
                          return (
                            <div
                              key={identifier}
                              className="sf-col-span-1 sf-row-span-1"
                            >
                              <span
                                className={`${
                                  isSticky ? 'sf-hidden' : ''
                                } sf-text-xs sf-whitespace-nowrap sf-text-light-text`}
                              >
                                {filter.label}
                              </span>
                              <div
                                className={`sf-text-xs sf-font-bold ${
                                  index === 0 ? 'sf-border-r' : ''
                                }`}
                              >
                                {(Boolean(selectedOption) && `${selectedOption}${filter.append || ''}`) || '-'}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className="sf-flex sf-ml-2 sf-pl-3 sf-pr-1 sf-mr-0 sf-h-3/4 sf-justify-end sf-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="sf-h-5 sf-w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      { (hasNonFeaturedFilters || hasFeaturedFilters) &&
      <div
        className={`${
          isSticky ? 'sf-shadow-md' : ''
        } sf-bg-white sf-z-20 sf-transition-shadow sf-duration-700 lg:sf-hidden`}
      >
        <div
          className={`${
            isSticky ? 'sf-py-2' : 'sf-py-4'
          } sf-transition-[padding] sf-duration-700 sf-flex sf-space-x-2 sf-max-w-screen-xl sf-mx-auto sf-px-4 md:sf-px-8 xl:sf-px-0`}
        >
          <div
            onClick={toggleMenu}
            className="sf-flex sf-w-full sf-justify-between lg:sf-hidden"
          >
            <div className="sf-flex sf-items-center sf-cursor-pointer sf-border sf-rounded-md sf-w-full sf-px-3 sf-py-2 lg:sf-px-3 lg:sf-py-3">
              <FilterIcon className="sf-h-4 sf-w-4" />
              <span className="sf-border-l sf-ml-3 sf-pl-3">Filters</span>
            </div>
          </div>
          {selectedOrderBy && setOrderBy && product &&
            <div className="lg:sf-hidden sf-w-full" data-name="product-header__orderby">
              <PackageOrderBySelect
                selectedOrderBy={selectedOrderBy}
                setOrderBy={setOrderBy}
                productName={product.name}
              />
            </div>
          }
        </div>
      </div>
      }
    </div>
  );
}
