const Star = ({filled, customClasses = 'sf-h-4 sf-w-4'}: {filled: boolean; customClasses?: string;}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" className={`${customClasses} sf-text-primary sf-inline-block`}
      fill={(filled && 'currentColor') || 'none'} stroke="currentColor"
    >
      <use href="#star" />
    </svg>
  );
};

const FiveStars = ({filled = false, customClasses}: {filled?: boolean; customClasses?: string;}) =>
  <>
    <Star filled={filled} customClasses={customClasses} />
    <Star filled={filled} customClasses={customClasses} />
    <Star filled={filled} customClasses={customClasses} />
    <Star filled={filled} customClasses={customClasses} />
    <Star filled={filled} customClasses={customClasses} />
  </>;


const Stars = ({score, customClasses}: {score: number; customClasses?: string;}) => {
  return (
    <div className="sf-flex sf-items-center">
      <div className="sf-relative sf-m-0 sf-p-0 sf-leading-none">
        {/* We cannot use TailwindCSS for this unless we instruct it to calculate all possible values between 0 and 100 */}
        <div
          className="sf-text-primary sf-absolute sf-t-0 sf-l-0 sf-overflow-hidden sf-whitespace-nowrap"
          style={{width: `${score * 10}%`}}
        >
          <FiveStars filled customClasses={customClasses} />
        </div>
        <div className="sf-z-0">
          <FiveStars customClasses={customClasses} />
        </div>
      </div>
      <span className="sf-pl-1 sf-text-primary sf-text-xs">{score}</span>
    </div>
  );
};

export default Stars;
