import {PackageOrderBy} from '../generated/graphql';
import {registerInteraction} from '../utils/tracking';

import OrderBy from './OrderBy';

interface PackageOrderByProps {
  selectedOrderBy: PackageOrderBy;
  setOrderBy: (orderBy: PackageOrderBy) => void;
  productName: string;
}

export default function PackageOrderBySelect({
  selectedOrderBy,
  setOrderBy,
  productName,
}: PackageOrderByProps) {

  return <OrderBy
    selectedOrderBy={selectedOrderBy}
    setOrderBy={(orderBy) => {
      setOrderBy(orderBy);
      registerInteraction(productName, 'vergelijker-sort', orderBy);
    }}
    orderBys={[
      {
        name: 'Beste deal per provider',
        value: PackageOrderBy.TOTAL_PRICE_PER_PROVIDER,
      },
      {
        name: 'Laagste bijbetaling',
        value: PackageOrderBy.PRODUCT_PRICE,
      },
      {name: 'Laagste totaalprijs', value: PackageOrderBy.TOTAL_PRICE},
      {
        name: 'Laagste maandprijs',
        value: PackageOrderBy.TOTAL_PER_MONTH_PRICE,
      },
    ]}
         />;
}
