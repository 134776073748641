const convertDeliveryTime = (value?: number | string | null): string => {
  if (!value) {
    return '?';
  }
  const deliveryTime = Number(value);

  if (deliveryTime === 98) {
    return 'pre-order';
  }
  if (deliveryTime === 99) {
    return '?';
  }

  return `${value}d`;
};

export default convertDeliveryTime;
