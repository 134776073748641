import {ReactElement, useEffect, useState} from 'react';

import {FeaturedDeal} from '../generated/graphql';
import {registerInteraction, registerView} from '../utils/tracking';

import Image from './Image';

interface FeaturedDealProps {
  featuredDeal: FeaturedDeal;
  renderSingle?: boolean;
  onClose: () => void;
  position: number;
}

const FeaturedDeal = ({
  featuredDeal,
  position,
  renderSingle = true,
  onClose,
}: FeaturedDealProps) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    registerView(
      'top-ad',
      `${featuredDeal.shop.name}|${featuredDeal.gaProduct}|p${position}`,
    );
  }, [featuredDeal.gaProduct, featuredDeal.shop.name, position]);

  const onCloseClick = () => {
    setVisible(false);
    onClose();
  };

  return (
    <div
      className={`${(!visible && '!sf-hidden') || ''} sf-flex-1 sf-grid sf-grid-cols-2 sf-grid-rows-[auto_auto_auto] sf-border sf-border-primary sf-rounded-md sf-p-4 sf-gap-1.5 md:sf-grid-rows-[auto_auto] even:sf-hidden even:md:sf-grid sf-relative`}
    >
      <button
        type="button"
        className="sf-absolute -sf-top-3 -sf-right-3 sf-cursor-pointer sf-text-white sf-rounded-full sf-bg-primary sf-w-6"
        onClick={onCloseClick}
        aria-label="Sluit advertentie"
      >
        ✕
      </button>
      <div className="sf-col-start-1 sf-row-start-1 sf-flex sf-flex-col sf-justify-between sf-gap-1">
        <div className="sf-text-gray-400 sf-text-xs">Gesponsord</div>
        <div className="sf-font-bold">{featuredDeal.title}</div>
      </div>
      {featuredDeal.shop.imageUrl &&
          <Image
            className="sf-col-start-2 sf-row-start-1 sf-justify-self-end sf-h-12 sf-w-36"
            alt={featuredDeal.shop.name}
            src={featuredDeal.shop.imageUrl}
            imgSet={[
              '{imgurl}?w=142&h=50&fit=fill 1x',
              '{imgurl}?w=284&h=100&fit=fill 2x',
            ]}
            loading="lazy"
          />
      }
      <div
        className={`sf-col-span-2 ${renderSingle ? 'md:sf-col-span-1' : ''}`}
      >
        <div className="sf-pb-2 sf-text-sm">{featuredDeal.subtitle}</div>
        {featuredDeal.content.length > 0 &&
          <ul className="sf-text-xs sf-text-gray-400">
            {featuredDeal.content.map((content, i) =>
              <li className="sf-pb-1" key={`${content}_${featuredDeal.id}`}>
                ✓ {content}
              </li>)}
          </ul>
        }
      </div>
      <a
        className={`${
          renderSingle
            ? 'md:sf-row-start-2 md:sf-col-span-1 md:sf-col-start-2 md:sf-min-w-[200px] md:sf-justify-self-end'
            : ''
        } sf-col-span-2 sf-row-start-3 sf-self-end sf-h-12 sf-py-3 sf-px-8 sf-rounded-md sf-text-center sf-border sf-border-gray-400 hover:sf-bg-primary hover:sf-text-white hover:sf-border-primary sf-cursor-pointer sf-text-sm`}
        href={featuredDeal.deeplink}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() =>
          registerInteraction(
            featuredDeal.shop.name,
            'top-ad-uitklik',
            `${featuredDeal.gaProduct}|p${position}`,
          )
        }
      >
        Bekijk&nbsp;aanbieding
      </a>
    </div>
  );
};

const FeaturedDeals = ({
  featuredDeals,
}: {
  featuredDeals: FeaturedDeal[];
}): ReactElement | null => {
  const [renderSingle, updateRenderSingle] = useState(
    featuredDeals.length === 1,
  );

  if (featuredDeals.length === 0) {
    return null;
  }

  return (
    <div className="sf-flex sf-gap-3">
      {featuredDeals.map((featuredDeal, i) =>
        <FeaturedDeal
          key={featuredDeal.id}
          featuredDeal={featuredDeal}
          renderSingle={renderSingle}
          onClose={() => updateRenderSingle(true)}
          position={i + 1}
        />)}
    </div>
  );
};

export default FeaturedDeals;
